<template>
  <div class="form">
    <div class="wrapper">
      <Backlink title="detail" service="mortgage" backlink="/mortgage/sell-during" />
      <div class="content">
        <div class="content__container">
          <Question
            service="mortgage"
            name="oldMortgageNotSold"
            question="question_8"
            successUrl="/mortgage/old-mortgage-not-sold-price"
            negativeUrl="/mortgage/self-money"
            :droppedSteps="dropped_steps"
            :negativeMissedSteps="dropped_steps"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Backlink from '@/components/base/Backlink.vue'
import Question from '@/components/Question.vue'

export default {
  name: 'Mortgage_old_mortgage_not_sold',
  components: { Backlink, Question },
  data: () => ({ dropped_steps: ['/mortgage/old-mortgage-not-sold-price'] })
}
</script>
